import { axiosInstance } from '../../../../services/axios-instance';

export const RequestForgotPassword = {
  postEmailForChangePassword: async (payload: any) => {
    return await axiosInstance
      .post('/user/request-change-password', payload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => response)
      .catch(error => error.response);
  },
};
