import React, { Component } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { ModalSuccessAndError } from '../../../components/ModalSuccessAndError';
import { error400 } from '../../../utils/messagePromises';
import { validationFormForgotPassword } from '../../../utils/validationForm';
import { ContainerForgotPassword } from '../../Login/components/CardContainer/WrapperForgotPassword';
import { LogoIcon } from '../../Login/components/LogoIcon/LogoIcon';
import { TitleForm } from '../../Login/components/TitleForm';
import { RequestPasswordForm } from './RequestPasswordForm';
import { RequestForgotPassword } from './services/RequestForgotPassword';
import styles from './style.module.css';

interface Props {
  name: string;
  title: string;
  width: number;
  height: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export class ForgotRequestPassword extends Component<Props> {
  state = {
    openYourModal: false,
    messageInfo: '',
    status: 0,
  };

  handleSubmit = async (values: any, actions: any) => {
    const messageSuccess = 'Pedido de redefinição de senha enviado';
    const response = await RequestForgotPassword.postEmailForChangePassword(values)
      .then(res => res)
      .catch(error => error.response);

    if (response && response.status) {
      this.setState({
        status: response.status,
        messageInfo: response.status === 200 ? messageSuccess : error400(response),
        openYourModal: true,
      });
    }

    await actions.setSubmitting(false);
  };

  setOpenYourModal = () => {
    this.setState({ openYourModal: false });
  };

  isRequestSuccessful = () => {
    const { status } = this.state;
    return status === 200;
  };

  setTitleSuccessOrErrorMessage = () => (this.isRequestSuccessful() ? 'Sucesso!' : 'Ops!');

  setMessageButton = () => (this.isRequestSuccessful() ? 'Ok!' : 'Fazer cadastro!');

  setModalAriaButton = () =>
    this.isRequestSuccessful() ? 'Voltar para o login' : 'Fazer cadastro';

  actionRedirect = () => {
    const { status } = this.state;
    return status === 200 ? navigate('/login') : navigate('/login?cadastro');
  };

  render() {
    const { title, name, onClick } = this.props;
    const { openYourModal, messageInfo, status } = this.state;
    const wrapperForm = cx('w-100 ph16 ph0-ns ph48-m ph48-l', styles.wrapperForm);
    return (
      <ContainerForgotPassword>
        <LogoIcon name={name} {...this.props} appearDesktop />
        <div className="flex justify-center items-center">
          <div className={wrapperForm}>
            <TitleForm title={title} />
            <Formik
              initialValues={{ email: '' }}
              onSubmit={this.handleSubmit}
              validationSchema={validationFormForgotPassword}
              // tslint:disable-next-line: jsx-no-lambda
              render={props => <RequestPasswordForm onClick={onClick} title={title} {...props} />}
            />
          </div>
        </div>
        <ModalSuccessAndError
          title={this.setTitleSuccessOrErrorMessage()}
          subTitle={messageInfo}
          isOpen={openYourModal}
          success={status === 200 ? true : false}
          closeModal={this.setOpenYourModal}
          actionBtn={this.actionRedirect}
          textBtnClose={this.setMessageButton()}
          primaryActionTitle={this.setModalAriaButton()}
        />
      </ContainerForgotPassword>
    );
  }
}
