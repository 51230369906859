import React from 'react';
import { Banner } from '../components/Banner';
import { Layout } from '../components/Layout';
import { ForgotRequestPassword } from '../modules/ForgotPassword/RequestPassword';

const SanboxForgotPass = () => {
  return (
    <Layout
      title="Esqueci minha senha | Autoscar"
      description="É muito fácil recuperar sua senha no site da Autoscar. Recupere seu acesso e confira os melhores veículos para compra e venda."
    >
      <div className="relative vh-100">
        <Banner />
        <ForgotRequestPassword
          title="Esqueceu sua senha?"
          name="SvgIconLogo"
          width={132}
          height={66}
        />
      </div>
    </Layout>
  );
};

export default SanboxForgotPass;
