import React, { FormEvent, ChangeEvent } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { navigate } from 'gatsby';
import { Button } from '../../../components/Button';
import { Link } from '../../../components/Link';
import { removeSpecialCharacters } from '../../../utils/mask';
import { InputForm } from '../../Login/components/InputForm/InputForm';

interface Props {
  errors: any;
  values: any;
  title: string;
  isSubmitting: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: any;
}

const handleKeyWrapper = (navigateFunction: () => Promise<void>) => {
  return (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && navigateFunction) {
      navigateFunction();
    }
  };
};

const navigateLogin = () => navigate('/login');
const navigateRegister = () => navigate('/login?cadastro');

const handleKeyDownLogin = handleKeyWrapper(navigateLogin);
const handleKeyDownRegister = handleKeyWrapper(navigateRegister);

const customInputComponent = ({ divClass, field, ...props }: any) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errormessage: !!props.form.touched[field.name] && props.form.errors[field.name],
  };
  const errorsInput = cx({
    mb16:
      !props.form.touched[field.name] ||
      (!props.form.errors[field.name] && !!props.form.touched[field.name]),
    mb8: !!props.form.touched[field.name] && props.form.errors[field.name],
  });

  return (
    <div className={errorsInput}>
      <InputForm {...normalizeFieldData} {...props} />
    </div>
  );
};

export const RequestPasswordForm = ({
  title,
  isSubmitting,
  handleSubmit,
  handleChange,
  values,
}: Props) => {
  return (
    <form onSubmit={handleSubmit} className="w-100 mb72-m mb72-l">
      <p className="f16 abbey trueno fw3 mt0 mb12 lh-paragraph">
        Digite o e-mail da sua conta Autoscar.
      </p>

      <Field
        name="email"
        id={title + 'user-email'}
        labeltext="E-mail"
        type="text"
        iconleft="SvgIconMail"
        iconId="request-password"
        value={values.email}
        onChange={handleChange}
        component={customInputComponent}
        validateOnChange={false}
        required
        mask={removeSpecialCharacters}
      />

      <div className="flex flex-column w-100 mt24">
        <Button type="submit" loading={isSubmitting} aria-label="Redefinir senha">
          Redefinir senha
        </Button>
        <span className="f16 abbey trueno fw3 mt24 mb4 flex items-center">
          É novo por aqui?
          <Link
            className="ml4 f16 fw7 pointer"
            onClick={navigateRegister}
            title="Criar uma nova conta"
            tabIndex={0}
            onKeyDown={handleKeyDownRegister}
          >
            Criar conta
          </Link>
        </span>
        <span className="f16 abbey trueno fw3 m8 flex items-center">
          Voltar para o
          <Link
            className="ml4 f16 fw7 pointer"
            onClick={navigateLogin}
            title="Fazer login"
            tabIndex={0}
            onKeyDown={handleKeyDownLogin}
          >
            Login
          </Link>
        </span>
      </div>
    </form>
  );
};
