export const error404 = (response: any) => {
  if (!response) {
    return null;
  }
  return response.status === 404 ? response.data.error.message : '';
};
export const error401 = (response: any) => {
  if (!response) {
    return null;
  }
  return response.status === 401 ? response.data.error.message : '';
};
export const error400 = (response: any) => {
  if (!response) {
    return null;
  }
  return response.status === 400 ? response.data.message : '';
};
